import React from 'react'
import {Route, Switch, Redirect} from 'react-router-dom'
import Home from './paginas/Home'
import {RUTAS_APLICACION} from './constantes/rutas'
import ResultadosPaciente from './paginas/ResultadosPaciente'
import {BrowserRouter} from 'react-router-dom/cjs/react-router-dom.min'

const Router = () => {
  return (
    <Switch>
      <BrowserRouter>
        <Route path={RUTAS_APLICACION.HOME}>
          <Home />
        </Route>

        <Route path={RUTAS_APLICACION.RESULTADOS}>
          <ResultadosPaciente />
        </Route>

        <Route exact path={RUTAS_APLICACION.MAIN}>
          <Redirect to={RUTAS_APLICACION.HOME} />
        </Route>
      </BrowserRouter>
    </Switch>
  )
}
export default Router
