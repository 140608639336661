import React, {useState} from 'react'

const ListaDesplegable = ({
  onChange,
  onBlur,
  estilosContenedor,
  estilosLista,
  titulo,
  opciones,
  valor,
}) => {
  const valorSeleccionado = opciones.find((val) => {
    return val.Id === valor
  })

  const [cambiarColorInput, setCambiarColorInput] = useState(false)

  const validarSeleccion = (valor) => {
    return setCambiarColorInput(Boolean(valor))
  }

  return (
    <div
      className={`${estilosContenedor} flex justify-start items-center flex-wrap`}
    >
      <label className="block text-13px font-medium text-cendiatra my-5px w-full">
        {titulo}
      </label>
      <select
        className={` ${estilosLista} ${
          cambiarColorInput
            ? 'text-cendiatra-gris-3'
            : 'text-cendiatra-gris-placeholder'
        }`}
        onChange={onChange}
        value={valorSeleccionado?.Id ?? valor}
        onBlur={onBlur ? onBlur : () => {}}
        onClick={(e) => validarSeleccion(e.target.value)}
      >
        <option key="-1" value="">
          {' '}
          Elegir{' '}
        </option>
        {opciones.map((item) => (
          <option
            key={item.Id}
            value={item.Id}
            className="text-cendiatra-gris-3"
          >
            {item.Nombre}
          </option>
        ))}
      </select>
    </div>
  )
}

export default ListaDesplegable
