import React from 'react'

export const  BotonSimple = ({
  texto,
  estilosBoton,
  deshabilitado,
  funcion,
  tipoDeBoton,
}) => {
  return (
    <button
      className={`${estilosBoton}`}
      disabled={deshabilitado}
      onClick={funcion}
      type={tipoDeBoton}
    >
      {texto}
    </button>
  )
}
