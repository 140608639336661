/** Textos a mostrar en la interfaz gráfica. */
export const TEXTO_VISUAL = {
  /** Pantalla inicial. */
  HOME: {
    /** Opciones de la barra de navegación. */
    NAVEGACION: {
      INICIO: 'Inicio',
    },
    /** Textos del lateral. */
    LATERAL: {
      NOMBRE_APLICACION: 'PORTAL DIMAR',
      BIENVENIDA: 'BIENVENIDO MARINO',
      SIGUENOS: 'Síguenos',
      OPCIONES: {
        DESCARGAR_LISTADO_IPS: 'Listado de IPS autorizadas',
        DESCARGAR_LISTADO_MEDICOS: 'Listado de médicos autorizadas',
      },
    },
    CONSULTA: {
      CONSULTA_CERTIFICADO: 'Consulta certificado DIMAR',
      NUMERO_DOCUMENTO: 'Ingrese el número de documento*',
      NUMERICO: 'NUMÉRICO',
      DESCRIPCION: 'DESCRIPCIÓN',
      CONSULTAR: 'Consultar',
      CONSULTA_MARINO: 'CONSULTA MARINO DIMAR',
      REGRESAR: 'Regresar',
    },
  },
}
