import Dexie from 'dexie'
import {PoblarCacheComun} from '../servicios-cache/Comun'

export const dbComun = new Dexie('CendiatraClienteCacheComun')

dbComun.version(1).stores({
  TiposExamen: 'Id, Nombre',
  EstadosOrden: 'Id, Nombre',
  ConceptosOrden: 'Id, Nombre',
  TiposDocumento: 'Id, Nombre',
  TiposUsuario: 'Id, Nombre',
  Sedes: 'Id , Nombre, CiudadId',
  Version: 'Numero, Fecha',
})

dbComun.version(2).stores({
  TiposExamen: 'Id, Nombre',
  Examenes: 'Id, Nombre, Observacion',
  EstadosOrden: 'Id, Nombre',
  ConceptosOrden: 'Id, Nombre',
  TiposDocumento: 'Id, Nombre',
  TiposUsuario: 'Id, Nombre',
  Sedes: 'Id , Nombre, CiudadId',
  Ciudades: 'Id, Nombre',
  Version: 'Numero, Fecha',
})

dbComun
  .version(3)
  .stores({
    TiposExamen: 'Id, Nombre',
    Examenes: 'Id, Nombre, Observacion',
    EstadosOrden: 'Id, Nombre',
    ConceptosOrden: 'Id, Nombre',
    TiposDocumento: 'Id, Nombre',
    TiposUsuario: 'Id, Nombre',
    Sedes: 'Id , Nombre, CiudadId',
    Ciudades: 'Id, Nombre',
    Version: 'Numero, Fecha',
  })
  .upgrade((trans) => {
    return PoblarCacheComun()
  })

dbComun
  .version(4)
  .stores({
    TiposExamen: 'Id, Nombre',
    EstadosOrden: 'Id, Nombre',
    ConceptosOrden: 'Id, Nombre',
    TiposDocumento: 'Id, Nombre',
    TiposUsuario: 'Id, Nombre',
    Sedes: 'Id , Nombre, CiudadId',
    Ciudades: 'Id, Nombre',
    Version: 'Numero, Fecha',
  })
  .upgrade((trans) => {
    return PoblarCacheComun()
  })

dbComun
  .version(5)
  .stores({
    TiposExamen: 'Id, Nombre',
    EstadosOrden: 'Id, Nombre',
    ConceptosOrden: 'Id, Nombre',
    TiposDocumento: 'Id, Nombre',
    TiposUsuario: 'Id, Nombre',
    Sedes: 'Id , Nombre, CiudadId',
    Ciudades: 'Id, Nombre',
    Version: 'Numero, Fecha',
  })
  .upgrade((trans) => {
    return PoblarCacheComun()
  })

dbComun
  .version(6)
  .stores({
    TiposExamen: 'Id, Nombre',
    EstadosOrden: 'Id, Nombre',
    ConceptosOrden: 'Id, Nombre',
    TiposDocumento: 'Id, Nombre',
    TiposUsuario: 'Id, Nombre',
    Sedes: 'Id , Nombre, CiudadId',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Version: 'Numero, Fecha',
    Departamentos: 'Id, Nombre, CodigoDian',
  })
  .upgrade((trans) => {
    return PoblarCacheComun()
  })


dbComun.open().catch(function (err) {
  console.error('Fallo al abrir el cache común: ' + (err.stack || err))
})
