import React from 'react'

export const  ModalConfirmacionEstandar = ({texto}) => {
  return (
    <div className="w-full flex flex-wrap m-2">
      <span className="w-full text-cendiatra text-xl font-bold">{texto}</span>
    </div>
  )
}


