import React, {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {TEXTO_VISUAL} from '../../constantes'
import {Input, ListaDesplegable, ErroresInput} from '../inputs'
import {BotonSimple} from '../Botones'
import {useHistory} from 'react-router-dom/cjs/react-router-dom'
import {
  ObtenerTiposDeDocumento,
  PoblarCacheComun,
} from '../../cache/servicios-cache'

export const ConsultaCertificado = () => {
  const {
    control,
    getValues,
    setValue,
    clearErrors,
    setError,
    trigger,
    formState: {errors, isValid},
  } = useForm({
    mode: 'all',
    defaultValues: {
      numeroDocumento: '',
      tipoDocumento: '',
    },
  })

  const [tipoDocumento, setTipoDocumento] = useState([])

  const [formularioAsignarTurno, setFormularioAsignarTurno] = useState({
    tipoDocumento: '',
  })

  useEffect(() => {
    const obtenerDatosIniciales = async () => {
      try {
        const cacheComun = await PoblarCacheComun()
        if (cacheComun?.status === 200) {
          const result = await ObtenerTiposDeDocumento()
          setTipoDocumento(result)
        }
      } catch (error) {
        console.log(error)
      }
    }
    obtenerDatosIniciales()
    trigger()
  }, [])

  const history = useHistory()

  const consultar = () => {
    const documento = getValues().numeroDocumento
    const tipoDocumento = getValues().tipoDocumento
    history.push('/resultadospaciente', {documento, tipoDocumento})
  }

  const manejarTipoDeDocumento = async (e) => {
    setFormularioAsignarTurno({
      ...formularioAsignarTurno,
      tipoDocumento: e.target.value,
    })
    if (e.target.value == '') {
      setValue('tipoDocumento', '')
      setError('tipoDocumento', {type: 'require', message: ''})
    } else {
      setValue('tipoDocumento', e.target.value)
      clearErrors('tipoDocumento')
    }
  }

  return (
    <>
      <div className="flex justify-center items-start">
        <h3 className="text-cendiatra text-2xl">
          {TEXTO_VISUAL.HOME.CONSULTA.CONSULTA_CERTIFICADO}
        </h3>
      </div>
      <div className="flex justify-center items-start mt-10 gap-8">
        <Controller
          name="tipoDocumento"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {value, onChange}, fieldState: {error}}) => (
            <ListaDesplegable
              funcion={manejarTipoDeDocumento}
              estilosContenedor={'w-4/12'}
              estilosLista={`rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm border ${
                !error ? 'border-cendiatra' : 'border-cendiatra-rojo-1'
              }`}
              titulo={'Tipo de documento*'}
              opciones={tipoDocumento}
              valor={value}
              onChange={onChange}
            />
          )}
        />
        <div className="w-4/12 flex justify-center items-center flex-wrap">
          <Controller
            name="numeroDocumento"
            control={control}
            rules={{required: true, pattern: /^[a-zA-Z0-9]+$/}}
            render={({field: {onChange, value}, fieldState: {error}}) => {
              return (
                <Input
                  titulo={TEXTO_VISUAL.HOME.CONSULTA.NUMERO_DOCUMENTO}
                  placeholder={TEXTO_VISUAL.HOME.CONSULTA.DESCRIPCION}
                  estilosInput={`rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm border ${
                    !error ? 'border-cendiatra' : 'border-cendiatra-rojo-1'
                  }`}
                  estilosContenedor={'w-full'}
                  valor={value}
                  onChange={onChange}
                  mostrarErrores={true}
                  tipo={'text'}
                />
              )
            }}
          />
          <ErroresInput
            erroresFormulario={errors}
            nombre={'numeroDocumento'}
            tipoError={'pattern'}
            mensaje={'Se permiten letras y números'}
          />
        </div>
      </div>
      <div className="flex justify-center items-start">
        <BotonSimple
          texto={TEXTO_VISUAL.HOME.CONSULTA.CONSULTAR}
          estilosBoton={`w-28 h-8 text-white bg-cover ${
            !isValid ? 'bg-cendiatra-gris-placeholder' : 'bg-cendiatra'
          } bg-no-repeat rounded-xl text-14px mt-6 bg-btnBg`}
          funcion={() => consultar()}
          deshabilitado={!isValid}
        />
      </div>
    </>
  )
}
