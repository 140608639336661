import {ObtenerReferencias} from '../../microservicios/Referencia'
import {dbComun} from '../bases-datos/dbComun'

export const PoblarCacheComun = async () => {
  try {
    await dbComun.open()

    await dbComun.table('Version').clear()
    await dbComun.TiposExamen.clear()
    await dbComun.EstadosOrden.clear()
    await dbComun.ConceptosOrden.clear()
    await dbComun.TiposDocumento.clear()

    const valoresReferencia = await ObtenerReferencias('TipoDocumento')

    // Agregar versión
    await dbComun.table('Version').put({
      Numero: valoresReferencia.data.version,
      Fecha: valoresReferencia.data.fechaVersion,
    })

    const valoresReferenciaAgrupados =
      valoresReferencia.data.listaReferencia.reduce(
        (grupoReferencia, elemento) => {
          const grupo = elemento.tipo
          grupoReferencia[grupo] = grupoReferencia[grupo] ?? []
          grupoReferencia[grupo].push(elemento)
          return grupoReferencia
        },
        {}
      )

    // Agregar tipos de documento
    await dbComun.TiposDocumento.bulkPut(
      valoresReferenciaAgrupados['TipoDocumento'].map((x) => ({
        Id: x.identificacion,
        Nombre: x.nombre,
      }))
    )
    return valoresReferencia
  } catch (error) {
    console.error('Error al poblar el caché común:', error.message)
  }
}
