let config = null

export const obtenerApiUrl = async () => {
  const config = await obtenerConfiguracion()
  return config.REACT_APP_API_URL_MICROSERVICIOS
}

export const obtenerNombreAmbiente = async () => {
  const config = await obtenerConfiguracion()
  return config.REACT_APP_ENV
}

export const obtenerVersionApp = async () => {
  const config = await obtenerConfiguracion()
  return config.REACT_APP_VERSION
}

const obtenerConfiguracion = async () => {
  if (config) {
    return config
  }
  const configFile = await fetch('./config.json')
  config = await configFile.json()

  console.log(config)
  return config
}
