import React, {useEffect, useState, useCallback, useContext} from 'react'
import ImagenUsuario from '../imagenes/usuarioImagen.svg'
import {useLocation} from 'react-router-dom'
import IconoImprimirActivado from '../imagenes/iconoImprimirVerde.svg'
import IconoImprimirDesactivado from '../imagenes/iconoImprimirGris.svg'
import {
  AgregarServiciosACache,
  ObtenerServicioPorId,
} from '../cache/servicios-cache'
import {ContextoAplicacion} from '../contexto'
import {ModalConfirmacionEstandar} from '../componentes/Modales'
import {TEXTO_VISUAL} from '../constantes'
import {ContenedorPagina} from '../componentes/paginas/ContenedorPagina'
import {CeldaConBoton} from '../componentes/Tablas'
import {ObtenerCertificadosDimarPorTipoYNumeroDocumento} from '../microservicios'
import moment from 'moment'
import {useHistory} from 'react-router-dom'
import {BotonSimple} from '../componentes/Botones'
import {EncabezadoFondo, TablaConPaginacionYFiltros} from '../componentes/Tablas'


const ResultadosPaciente = () => {
  const [datos, setDatos] = useState({})
  const [foto, setFoto] = useState('')
  const [examenes, setExamenes] = useState([])
  const history = useHistory()

  const {state} = useLocation()

  const contextoAplicacion = useContext(ContextoAplicacion)

  const ObtenerInformacionCertificados = async () => {
    const {documento, tipoDocumento} = state

    try {
      const res = await ObtenerCertificadosDimarPorTipoYNumeroDocumento(
        tipoDocumento,
        documento
      )

      if (res.status === 200) {
        const data = res.data

        setDatos({
          'Nro. Documento': documento,
          Apellidos: data[0]?.apellidos ?? '',
          Nombres: data[0]?.nombres ?? '',
        })

        setFoto(data[0].urlFotoUsuario)

        const promesasExamenes = data.map(async (exam) => {
          const service = await ObtenerServicioPorId(exam.servicioId)

          return {
            servicio: service?.Nombre.toUpperCase(),
            fechaExpedicion: exam.fechaExpedicion,
            fechaExpiracion: exam.fechaExpiracion,
            nroCertificado: exam.numeroCertificado,
            conceptoMedico: exam.conceptoCertificado,
            urlCertificado: exam.urlCertificado,
          }
        })

        const examenes = await Promise.all(promesasExamenes)

        setExamenes(examenes)
      } else {
        nohayDatos()
      }
    } catch (error) {
      console.error(
        'Error al obtener información de certificados:',
        error.message
      )
    }
  }

  useEffect(() => {
    ObtenerInformacionCertificados()
  }, [])

  const obtenerInformacionOrden = useCallback(async () => {
    await AgregarServiciosACache()
  }, [])

  useEffect(() => {
    obtenerInformacionOrden().catch(console.error)
  }, [obtenerInformacionOrden])

  const manejarCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const regresar = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
    history.push('/')
  }

  const nohayDatos = () => {
    contextoAplicacion.setModal({
      abierto: true,
      contenido: (
        <div className="text-center w-full">
          <h3 className="text-cendiatra mb-6 text-2xl font-bold">
            NÚMERO DE DOCUMENTO NO ENCONTRADO
          </h3>
          <p className="text-cendiatra text-xl font-bold">
            POR FAVOR COLOCAR UN NÚMERO DE DOCUMENTO VALIDO
          </p>
        </div>
      ),
      botones: [
        {
          nombre: 'Otra Consulta',
          click: regresar,
        },
      ],
    })
  }

  const clickModuloImprimir = (urlCertificado) => {
    if (urlCertificado) {
      var win = window.open(urlCertificado, '_blank')
      win.focus()
    } else {
      contextoAplicacion.setModal({
        abierto: true,
        contenido: (
          <ModalConfirmacionEstandar
            texto={'EL CERTIFICADO NO HA SIDO GENERADO'}
          />
        ),
        botones: [
          {
            nombre: 'Volver',
            click: manejarCerrarModal,
          },
        ],
      })
    }
  }

  const columnsPaciente = React.useMemo(() => [
    {
      Header: (row) => (
        <EncabezadoFondo titulo={'Servicio'} paddingFondoClaro={''} />
      ),
      accessor: 'servicio',
      className: 'w-3/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16 rounded-l-lg pl-1',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: (row) => (
        <EncabezadoFondo
          titulo={'Fecha de expedición'}
          paddingFondoClaro={''}
        />
      ),
      accessor: 'fechaExpedicion',
      className: 'w-2/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16 ',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      Cell: (row) =>
        moment(row.row.original.fechaExpedicion).format('YYYY-MM-DD'),
    },

    {
      Header: (row) => (
        <EncabezadoFondo
          titulo={'Fecha de expiración'}
          paddingFondoClaro={''}
        />
      ),
      accessor: 'fechaExpiracion',
      className: 'w-2/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16 ',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      Cell: (row) =>
        moment(row.row.original.fechaExpiracion).format('YYYY-MM-DD'),
    },
    {
      Header: (row) => (
        <EncabezadoFondo titulo={'No. Certificado'} paddingFondoClaro={''} />
      ),
      accessor: 'nroCertificado',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16 ',
      className: 'w-2/12',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: (row) => (
        <EncabezadoFondo
          titulo={'Concepto certificado'}
          paddingFondoClaro={''}
        />
      ),
      accessor: 'conceptoMedico',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16 ',
      className: 'w-2/12',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: (row) => (
        <EncabezadoFondo
          titulo={'Imprimir Certificado'}
          paddingFondoClaro={''}
        />
      ),
      id: 'CeldaConBoton',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16 rounded-r-lg pr-1',
      className: 'w-1/12',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      Cell: (row) => (
        <CeldaConBoton
          funcion={() => clickModuloImprimir(row.row.original.urlCertificado)}
          imagen={
            row.row.original.urlCertificado
              ? IconoImprimirActivado
              : IconoImprimirDesactivado
          }
          deshabilitado={Boolean(row.row.original.urlCertificado)}
        />
      ),
    },
  ])

  return (
    <ContenedorPagina tituloPagina={TEXTO_VISUAL.HOME.CONSULTA.CONSULTA_MARINO}>
      <div className="w-full flex justify-center items-center flex-wrap ">
        <div className="w-full flex">
          <img
            className="h-40 w-40"
            src={foto ? foto : ImagenUsuario}
            alt="imagen usuario"
            style={{borderRadius: '50%'}}
          />

          {Object.keys(datos).map((dato) => (
            <div className="flex flex-wrap justify-center items-center">
              <div className="w-3/4 flex flex-wrap justify-center items-center">
                <span className="w-full text-lg text-start text-cendiatra font-semibold">
                  {dato}
                </span>
                <span className="w-full text-xl text-gray-400 text-start">
                  {datos[`${dato}`]}
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="w-full flex flex-wrap justify-center items-center">
          <div className="w-full flex justify-center items-center">
            <TablaConPaginacionYFiltros
              columns={columnsPaciente}
              data={examenes}
              paginacion={true}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-center items-start">
        <BotonSimple
          texto={TEXTO_VISUAL.HOME.CONSULTA.REGRESAR}
          estilosBoton={`w-28 h-8 text-white bg-cover bg-cendiatra
           bg-no-repeat rounded-xl text-14px mt-6 bg-btnBg`}
          funcion={() => regresar()}
        />
      </div>
    </ContenedorPagina>
  )
}

export default ResultadosPaciente
