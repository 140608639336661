import CendiatraLogo from '../imagenes/logoCendiatra.svg'
import LogoMaritima from '../imagenes/logo-general-maritima.png'
import ImagenEstandar from './ImagenEstandar'
import {TEXTO_VISUAL} from '../constantes'

const BarraDeNavegacion = () => {
  return (
    <div className=" w-full flex justify-between items-center relative bg-white py-2">
      <div className="w-3/12 flex justify-center items-center">
        <ImagenEstandar
          imagen={CendiatraLogo}
          texto={'logoCendiatra'}
          estilosPersonalizados={'w-52 ml-5'}
        />
      </div>
      <div className="flex justify-center items-center w-6/12">
        <div className="flex h-24 justify-center items-center bg-white w-full rounded rounded-t-xl rounded-b-xl border  border-opacity-50 border-cendiatra shadow-2xl p-2 ">
          <h2 className="text-cendiatra text-3xl font-bold">
            {TEXTO_VISUAL.HOME.LATERAL.NOMBRE_APLICACION}
          </h2>
        </div>
      </div>
      <div className="w-3/12 flex justify-center items-center">
        <ImagenEstandar
          imagen={LogoMaritima}
          texto={'logoCendiatra'}
          estilosPersonalizados={'w-52 ml-5'}
        />
      </div>
    </div>
  )
}

export default BarraDeNavegacion
