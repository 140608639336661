import {getRestApi} from './ApiAxios'

export const ObtenerCertificadosDimarPorTipoYNumeroDocumento = async (
  tipoDocumento,
  numeroDocumento
) => {
  const restApi = await getRestApi()
  return await restApi.get(
    'fachada-function/ObtenerCertificadosDimarPorTipoYNumeroDocumento',
    {
      params: {
        tipoDocumento,
        numeroDocumento,
      },
    }
  )
}
