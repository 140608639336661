import React from 'react'

const CeldaConBoton = ({funcion, imagen}) => {
  return (
    <div
      className="w-full flex justify-center items-center cursor-pointer"
      onClick={funcion}
    >
      <img className={'w-10 h-10'} src={imagen} alt="Agregar" />
    </div>
  )
}

export default CeldaConBoton
