import React from 'react'
import Carrusel from './Carrusel'
import banner1 from '../../imagenes/banners/banner1.png'
import banner2 from '../../imagenes/banners/banner2.png'
import banner3 from '../../imagenes/banners/banner3.png'

const data = [
  {
    titulo: 'bienvenida',
    enlace: '/',
    url: banner1,
  },
  {
    titulo: 'Sedes',
    enlace: '/',
    url: banner2,
  },
  {
    titulo: 'trabajando',
    enlace: '/',
    url: banner3,
  },
]

export const Noticias  = () => {
  return (
    <>
      <div className="w-full flex justify-center items-center px-10 mt-10">
        <Carrusel data={data} />
      </div>
    </>
  )
}

