import React from 'react'

import './OpcionLateral.css'

/** Componente para mostrar una opción en el lateral del home. */
export const OpcionLateral = ({texto, onOpcionClick}) => {
  return (
    <div
      className="opcion-lateral border-y border-solid border-gray-300 cursor-pointer py-2 text-left text-cendiatra-gris-2 w-full"
      onClick={onOpcionClick}
    >
      {texto}
    </div>
  )
}
