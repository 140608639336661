import axios from 'axios'
import {useContext, useEffect} from 'react'
import {PoblarCacheComun} from '../cache/servicios-cache/Comun'
import {obtenerVersionActual} from '../cache/servicios-cache/Version'
import {ContextoAplicacion} from '../contexto/ContextoAplicacion'
import {getRestApi} from '../microservicios/ApiAxios'

const AxiosInterceptador = ({children}) => {
  const contextoAplicacion = useContext(ContextoAplicacion)

  const errorInterceptor = (error) => {
    contextoAplicacion.setCargando(false)
    const respuesta = error.response.data
    contextoAplicacion.setModal({
      abierto: true,
      titulo:
        typeof respuesta === 'string'
          ? respuesta.trim().length > 0
            ? respuesta.trim()
            : 'Ocurrió un error, inténtelo más tarde'
          : typeof respuesta === 'object'
          ? respuesta.message
          : 'Ocurrió un error en el servicio, inténtelo más tarde',
      botones: [
        {
          nombre: 'Cerrar',
          click: () => {
            contextoAplicacion.setModal({abierto: false, botones: []})
          },
        },
      ],
    })

    return Promise.reject(error)
  }

  const completadoInterceptor = async (respuesta) => {
    contextoAplicacion.setCargando(false)
    if (respuesta.status == 201) {
      await PoblarCacheComun(true)
      respuesta.config.headers['VersionCache'] = await obtenerVersionActual()
      let nuevaRespuesta = await axios.request(respuesta.config)
      return nuevaRespuesta
    } else {
      return respuesta
    }
  }

  const requestInterceptor = async (config) => {
    contextoAplicacion.setCargando(true)

    config.headers.common['R-Authorization'] = `true`

    if (config === 'Archivo') {
      config.headers.common['Content-Type'] = 'form/data'
      config.headers.common['Accept'] = 'form/data'
    } else {
      config.headers.common['Content-Type'] = 'application/json'
      config.headers.common['Accept'] = 'application/json'
    }
    config.headers.common['VersionCache'] = await obtenerVersionActual()
    return config
  }

  useEffect(() => {
    ;(async () => {
      const restApi = await getRestApi()
      const request = restApi.interceptors.request.use(requestInterceptor)
      const interceptor = restApi.interceptors.response.use(
        completadoInterceptor,
        errorInterceptor
      )
      return () => {
        restApi.interceptors.response.eject(interceptor)
        restApi.interceptors.request.eject(request)
      }
    })()
  }, [])

  return children
}

export default AxiosInterceptador
