import React, {useState, createContext} from 'react'

export const ContextoAplicacion = createContext('Default Value')

export const Provider = ({children}) => {
  const [modal, setModal] = useState({
    abierto: false,
    titulo: '',
    contenido: '',
    botones: [],
  })
  const [cargando, setCargando] = useState(false)
  const [infoEmpresa, setInfoEmpresa] = useState({nombre: '', imagen: '', tipoFacturacion: ''})

  return (
    <ContextoAplicacion.Provider
      value={{
        modal: modal,
        setModal: setModal,
        cargando: cargando,
        setCargando: setCargando,
        infoEmpresa: infoEmpresa,
        setInfoEmpresa: setInfoEmpresa,
      }}
    >
      {children}
    </ContextoAplicacion.Provider>
  )
}
