import React from 'react'
import ReactDom from 'react-dom'

const OVERLAY_STYLE = {
  position: 'fixed',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  rigth: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, .7)',
  zIndex: 1000,
}
const MODAL_STYLES = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '50px',
  zIndex: 1000,
}

const Spinner = () => {
  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLE}>
        <div style={MODAL_STYLES}>
          <div
            style={{
              border: '5px solid #f3f3f3',
              borderTop: '5px solid #16d47b',
              borderRadius: '50%',
              width: '50px',
              height: '50px',
              animation: 'spin 0.8s linear infinite',
              margin: '20px auto',
            }}
          ></div>
        </div>
      </div>
    </>,
    document.getElementById('portal')
  )
}

export default Spinner