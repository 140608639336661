import React from 'react'
import {Noticias, ConsultaCertificado, BarraLateral} from '../componentes/Home'

const Home = () => {
  return (
    <div>
      <div className="w-full absolute inset-0 flex flex-wrap justify-center items-end mt-36">
        <BarraLateral />
        <div className="w-82% h-auto flex flex-wrap justify-center items-center ">
          <div className="w-full z-40 consulta">
            <ConsultaCertificado />
          </div>
          <div className="w-full py-10">
            <Noticias />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
