import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import {Provider} from './contexto/ContextoAplicacion'
import AxiosInterceptador from './interceptador/AxiosInterceptador'
import moment from 'moment'
import 'moment/locale/es'

moment.locale('es')

ReactDOM.render(
  <React.StrictMode>
    <Provider>
      <AxiosInterceptador>
        <App />
      </AxiosInterceptador>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
