import axios from 'axios'
import {obtenerApiUrl} from '../config'

let restApi = null

const initializeRestApi = async () => {
  const apiConfig = await obtenerApiUrl()
  restApi = axios.create({
    baseURL: apiConfig,
    headers: {
      'R-Authorization': 'true',
    },
  })
}

const getRestApi = async () => {
  if (!restApi) {
    await initializeRestApi()
  }
  return restApi
}

export let idToken = '...'

export {restApi, getRestApi}
